import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';
import * as Icon from './Icon';

class SpIccidOrPinSelector extends React.Component {
  render() {
    let {onChangeSearchByIccid, isSearchByIccid, changePinInput, changeIccidInput, searchPin, searchIccid, status, hidePin} = this.props;

    return (
      <Widget.Container>
        {(status && status !== '200') && (<Text css="color: red">卡片序號或號碼錯誤</Text>)}
        {!hidePin && (
          <Widget.Row align="center" extraCss="margin-bottom: 10px;">
            <RadioButton
              isSelected={!isSearchByIccid}
              onSelect={() =>onChangeSearchByIccid(false)}
              extraStyle={{ marginRight: 10 }}
            />
            <CountryCode css="margin-right: 8px;">
              <option>+852</option>
            </CountryCode>
            <FormInput
              value={searchPin}
              onFocus={() => onChangeSearchByIccid(false)}
              placeholder="本機號碼"
              onChange={e => changePinInput(e.target.value)}
            />
          </Widget.Row>
        )}

        <Widget.Col extraCss="margin-bottom: 20px;">
          <Widget.Row align="center" extraCss="margin-bottom: 5px;">
            <RadioButton
              isSelected={isSearchByIccid}
              onSelect={() => onChangeSearchByIccid(true)}
              extraStyle={{ marginRight: 10 }}
            />
            <FormInput
              value={searchIccid}
              onFocus={() => onChangeSearchByIccid(true)}
              placeholder="卡片序號"
              onChange={e => changeIccidInput(e.target.value)}
            />
          </Widget.Row>
          <Text css="font-size: 12px; margin-left: 34px;">提醒您：卡號後兩碼不需填寫</Text>
        </Widget.Col>
      </Widget.Container>
    )
  }
}

const CheckBox = styled.input`
  ${props => props.css};
`;

const Text = styled.div`
  max-width: 430px;
  letter-spacing: 1px;
  font-weight: 200;
  font-size: 16px;
  color: #dbdbdb;
  ${props => props.css};
`;

const CountryCode = styled.select`
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: white;
  width: 60px;
  height: 38px;
  font-weight: 200;
  font-size: 16px;
  color: #3b3b3b;

  :focus {
    outline: none;
  }

  ${props => props.css};
`;

const FormInput = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 8px 12px;
  height: 38px;
  font-weight: 200;
  font-size: 16px;
  color: #3b3b3b;

  :focus {
    outline: none;
  }
`;

const RadioButton = props => {
  return props.isSelected ? (
    <Icon.RadioButtonChecked
      onClick={() => props.onSelect && props.onSelect()}
      color="white"
      style={{ width: 24, height: 24, ...props.extraStyle }}
    />
  ) : (
    <Icon.RadioButtonUnchecked
      onClick={() => props.onSelect && props.onSelect()}
      color="white"
      style={{ width: 24, height: 24, ...props.extraStyle }}
    />
  );
};


export default SpIccidOrPinSelector;
