import React from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import * as Widget from './Widget';
import ProgramItem from './ProgramItem';

class SpPurchaseDepositSection extends React.Component {
    render() {
        let {products} = this.props;
        return (
            <Wrapper img={`/images/sp-background/background01.jpg`}>
                <DarkContainer>
                    <Widget.Row
                        wrap={'true'}
                        justify='space-around'
                        extraCss={`
                            max-width: 1024px;
                            margin: 0 auto;
                            padding: 110px 20px 70px;
                            @media screen and (max-width: 768px) {
                                padding: 70px 20px;
                            }
                        `}
                    >
                        <Widget.Col
                            extraCss={`
                                margin-bottom: 25px;
                                max-width: 480px;
                                @media screen and (max-width: 600px) {
                                    max-width: 100%;
                                }
                            `}
                        >
                            <Title>線上購買 3天內取件</Title>
                            <Text extraCss={'margin-bottom: 20px;'}>
                                方便的購買流程，即使是首次購買也能輕鬆上手一卡支援多國，
                                無須換卡即可暢遊不同國度，30天/12GB降速吃到飽用量充足適合隨時需要網路的您。
                            </Text>
                            <Click>參考常見問題</Click>
                        </Widget.Col>
                        {
                            products.map(item => {
                                    return <ProgramItem
                                                key={item.id}
                                                item={item}
                                            />
                            })
                        }
                    </Widget.Row>

                    <Widget.Row
                        wrap={'true'}
                        justify='space-around'
                        extraCss={`
                            max-width: 1024px;
                            margin: 0 auto;
                            padding: 0px 20px;
                        `}
                    >
                        <Widget.Col align='flex-start' extraCss='margin-bottom: 25px; width: 99%;'>
                            <Title>隨時隨地 即時加購</Title>
                            <Text>
                                多種加購方案供您選擇，符合您的需求隨時隨地線上加購，
                                延續您的網路使用加購後即可開通使用，節省您的寶貴時間。
                            </Text>
                        </Widget.Col>
                    </Widget.Row>
                    <Widget.Row
                        wrap={'true'}
                        justify='space-around'
                        extraCss={`
                            max-width: 1024px;
                            margin: 0 auto;
                            padding: 0px 20px;
                        `}
                    >
                    {
                        products.map(item => {
                              return <ProgramItem
                                            key={item.id}
                                            item={item}
                                        />
                        })
                    }
                    </Widget.Row>
                </DarkContainer>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
`;

const DarkContainer = styled.div`
    background-color: rgba(0, 0, 0, .8);
    padding-bottom: 95px;
`;

const Title = styled.div`
    font-size: 50px;
    font-weight: 400;
    color: #009eda;

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
`;

const Text = styled.div`
    font-size: 14px;
    color: #dbdbdb;
    line-height: 21px;
    letter-spacing: 1px;
    ${props => props.extraCss};
`;

const Click = styled.div`
    font-weight: 300;
    font-size: 18px;
    color: #009eda;
`;

export default connect((state, ownProps) => ({
    products: Selectors.getSPProductList(state),
}), ActionCreator)(SpPurchaseDepositSection);
