import React from 'react';
import styled from 'styled-components';
import SpBuySection from '../../src/Components/SpBuySection';
import SpDepositSection from '../../src/Components/SpDepositSection';
import SpCheckSection from '../../src/Components/SpCheckSection';

class SpBuyDepositCheckSection extends React.Component {
    render() {
        return (
            <Wrapper img={`/images/sp-background/background01.jpg`}>
                <DarkContainer>
                    <SpBuySection />
                    <SpDepositSection />
                    <SpCheckSection />
                </DarkContainer>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
`;

const DarkContainer = styled.div`
    background-color: rgba(0, 0, 0, .8);
    padding-bottom: 95px;
`;

export default SpBuyDepositCheckSection;