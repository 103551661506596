import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import * as Widget from './Widget';
import * as Icon from './Icon';
import Dialog from './Dialog';
import SpIccidOrPinSelector from './SpIccidOrPinSelector';

class SpDepositDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      errorState: false,
      selectorArr: [{
        isSearchByIccid: true,
        searchPin: '',
        searchIccid: '',
        submitting: false,
      }],
    };
  }

  render() {
    let { toClose } = this.props;
    let { selectorArr, errorState , submitting} = this.state;

    return (
      <Dialog hasInputField extraStyle={{ backgroundColor: '#2c4858', padding: 15 }}>
        <div style={{ position: 'absolute', right: 20 }}>
          <Icon.Close onClick={toClose} color={'white'} />
        </div>
        <h3 style={{ textAlign: 'center', color: '#a8c0c7' }}>立即加購(至多10張)</h3>

        <Widget.Container extraCss="overflow: scroll; max-height: 70vh;" >

          {selectorArr.map((data, i) => (
            <div key={i} style={{ margin: 30, position: 'relative' }}>
              <h4 style={{ color: 'white' }}>卡片{i + 1}</h4>
              <SpIccidOrPinSelector
                hidePin
                isSearchByIccid={data.isSearchByIccid}
                searchPin={data.searchPin}
                searchIccid={data.searchIccid}
                status={data.status}
                onChangeSearchByIccid={isSearchByIccid => {
                  selectorArr[i] = {
                    ...selectorArr[i],
                    isSearchByIccid
                  }
                  this.setState({ selectorArr });
                }}
                changePinInput={searchPin => {
                  let selectorArrClone = [...selectorArr];
                  selectorArrClone[i] = {
                    ...selectorArr[i],
                    searchPin
                  }
                  this.setState({ selectorArr: selectorArrClone });
                }}
                changeIccidInput={searchIccid => {
                  let selectorArrClone = [...selectorArr];
                  selectorArrClone[i] = {
                    ...selectorArr[i],
                    searchIccid
                  }
                  this.setState({ selectorArr: selectorArrClone });
                }}
              />
              {selectorArr.length > 1 && (
                <div style={{ position: 'absolute', right: 0, top: 0 }}>
                  <Icon.Close onClick={() => this.setState({
                    selectorArr: [
                      ...selectorArr.slice(0, i),
                      ...selectorArr.slice(i + 1),
                    ]
                  })} color={'white'} />
                </div>
              )}
            </div>
          )

          )}
        </Widget.Container>
        <Widget.Col extraCss={{ alignItems: 'center' }}>
          {selectorArr.length <= 9 ? (
            <Icon.AddCircle
              style={{width:40,height:40, marginTop: 10, marginBottom: 10}}
              onClick={() => {
                selectorArr.push({
                  isSearchByIccid: true,
                  searchPin: '',
                  searchIccid: '',
                });
                this.setState({ selectorArr: selectorArr })
              }}
            />
          ) : (
              <></>
            )}
          { submitting ? (
            <Widget.Spinner/>
          ):(
            <Widget.BlueButton
              extraCss="margin-top: 15px;"
              onClick={this._createOutputArray}
            >加購上方輸入之卡片
            </Widget.BlueButton>
          )}
          
        {
          errorState && <p style={{color: 'red', marginTop: 10}}>請填寫卡號或電話號碼</p>
        }

        </Widget.Col>
      </Dialog>
    )
  }

  _createOutputArray = () => {
    let { selectorArr } = this.state;
    let output = selectorArr
      .filter(data => {
          if(!!data.searchIccid == false && !!data.searchPin == false) {
            return false
          } else {
            return true
          }
        })

    this._exportSelectorValues(output)
  }

  _exportSelectorValues = output => {
    let { appActions, onClickDeposit } = this.props;

    if(output.length === 0) {
      this.setState({errorState: true});
      return;
    }

    const list = output.map(data => {
      if (data.isSearchByIccid) {
        return data.searchIccid;
      }
      return data.searchPin;
    });
    this.setState({submitting: true})
    appActions
      .spValidateByIccidList(list)
      .then(result => {
        const isFail = !!result.find(item => item.status !== '200')
        if (isFail) {
          this.setState({ selectorArr: output.map((o, index) => ({
            ...o,
            status: result[index].status,
          }))});
          return;
        }

        onClickDeposit(list);
      })
  }
}

export default connect(
  null,
  ActionCreator
)(SpDepositDialog);
