import React from 'react';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import styled from 'styled-components';
import moment from 'moment';
import * as Widget from './Widget';
import * as Icon from './Icon';
import SpIccidOrPinSelector from './SpIccidOrPinSelector';

class SpCheckSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchByIccid: false,
      searchPin: '',
      searchIccid: '',
      result: null,
      isFetching: false,
      error: '',
    };
  }

  render() {
    let {
      result,
      isSearchByIccid,
      isFetching,
      error,
      searchIccid,
      searchPin,
    } = this.state;
    return (
      <Wrapper id="check">
        <Widget.MaxWidth
          width={'1024'}
          extraCss={`
                        padding: 0px 30px;
                        @media screen and (max-width: 600px) {
                            padding: 0px 20px;
                        }
                    `}
        >
          <CheckBlock>
            <Logo
              img="/images/sp-logo/Global-Star-Logo.png"
              css="margin-bottom: 15px;"
            />
            <Title css="margin-bottom: 10px;">一個步驟 即時掌握</Title>
            <Text css="margin-bottom: 50px;">
              只要輸入卡片上的序號，或是
              SIM的本機號碼，即可查詢您目前的剩餘流量。
            </Text>

            <Widget.Container extraCss="max-width: 320px; min-width: 240px; width: 100%; margin-bottom: 40px;">
              {result ? (
                <Widget.Container extraCss="width: 100%; padding: 20px;">
                  <Widget.Row justify="center" extraCss="position: relative;">
                    <ResultText css="font-size: 20px; margin: 10px 0px; padding-left: 15px; text-align: center">
                      查詢結果
                    </ResultText>
                  </Widget.Row>
                  <ResultText css="margin: 10px 0px; padding-left: 15px;">
                    查詢卡號 | {result.iccid}
                  </ResultText>
                  <ResultText css="margin: 10px 0px; padding-left: 15px;">
                    查詢號碼 | {result.pin}
                  </ResultText>
                  <ResultText css="margin-bottom: 10px; padding-left: 15px;">
                    到期日期 | {result.expiryDate ? moment(result.expiryDate).format('YYYY/MM/DD') : ''}
                  </ResultText>
                  <ResultText css="margin: 10px 0px; padding-left: 15px;">
                    A區目前已使用流量 | {convertMBUnit(Number(result.quotaA))}
                  </ResultText>
                  <ResultText css="margin: 10px 0px; padding-left: 15px;">
                    B區目前已使用流量 | {convertMBUnit(Number(result.quotaB))}
                  </ResultText>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                    <ResetButton
                      type="button"
                      onClick={() => this.setState({
                        isSearchByIccid: false,
                        searchPin: '',
                        searchIccid: '',
                        result: null,
                      })}>
                      重新查詢
                    </ResetButton>
                  </div>
                </Widget.Container>
              ) : (
                <Widget.Container>
                  <SpIccidOrPinSelector
                    isSearchByIccid={isSearchByIccid}
                    onChangeSearchByIccid={isSearchByIccid => this.setState({isSearchByIccid})}
                    searchPin={searchPin}
                    searchIccid={searchIccid}
                    changePinInput={searchPin => this.setState({searchPin})}
                    changeIccidInput={searchIccid => this.setState({searchIccid})}
                  />
                  {error && <Error>{error}</Error>}
                  <SubmitButton
                    disabled={isFetching}
                    onClick={() => !isFetching && this._searchQuota()}
                  >
                    {isFetching ? '查詢中...' : '查詢'}
                  </SubmitButton>
                </Widget.Container>
              )}
            </Widget.Container>
          </CheckBlock>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  _searchQuota = () => {
    let { appActions } = this.props;
    let { isSearchByIccid, searchIccid, searchPin } = this.state;
    if ((isSearchByIccid && !searchIccid) || (!isSearchByIccid && !searchPin)) {
      this.setState({ error: '請輸入查詢號碼！' });
      return;
    }

    this.setState({ isFetching: true });

    if (isSearchByIccid) {
      appActions
        .spSearchByIccid(searchIccid)
        .then(result => {
          this.setState({ result, isFetching: false, error: '' });
        })
        .catch(err => {
          if (err.status === 404) {
            this.setState({ error: '卡片號碼錯誤' });
          } else if (err.status === 492) {
            this.setState({ error: '發生錯誤！請聯繫 GLOBAL STAR 員工！' });
          } else {
            this.setState({ error: '發生錯誤！請再試一次！' });
          }
          this.setState({ isFetching: false });
        });
    } else {
      appActions
        .spSearchByPin(searchPin)
        .then(result => {
          this.setState({ result, isFetching: false, error: '' });
        })
        .catch(err => {
          if (err.status === 404) {
            this.setState({ error: 'PIN號碼錯誤' });
          } else if (err.status === 492) {
            this.setState({ error: '發生錯誤！請聯繫 GLOBAL STAR 員工！' });
          } else {
            this.setState({ error: '發生錯誤！請再試一次！' });
          }
          this.setState({ isFetching: false });
        });
    }
  };
}

const Wrapper = styled.div``;

const CheckBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  padding: 30px 20px;
  max-width: 650px;
  min-width: 280px;
  background-image: linear-gradient(331deg, #009eda 30%, #1ea55c);
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.3);
`;

const Logo = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 260px;
  height: 28px;
  ${props => props.css};
`;

const Title = styled.div`
  letter-spacing: 3px;
  font-size: 33px;
  color: #dbdbdb;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }

  ${props => props.css};
`;

const Text = styled.div`
  max-width: 430px;
  letter-spacing: 1px;
  font-weight: 200;
  font-size: 16px;
  color: #dbdbdb;
  ${props => props.css};
`;

const SubmitButton = styled.div`
  float: right;
  padding: 9px 15px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => (props.disabled ? 'grey' : '#1ea55c')};
  cursor: pointer;
  min-width: 65px;

  text-align: center;
  font-weight: 200;
  font-size: 16px;
  color: white;
`;

const ResetButton = styled.button`
  background-color: #1ea55c;
  color: #fff;
  font-size: 16px;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 7px 15px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

const ResultText = styled.div`
  width: 100%;
  font-weight: 200;
  font-size: 16px;
  color: #fff;
  ${props => props.css};
`;

const Error = styled.div`
  color: #ab1900;
  font-weight: 400;
  text-align: right;
  font-size: 16px;
  margin-bottom: 5px;
`;

function convertMBUnit(credit) {
  if (credit < 1024) {
    return `${credit}MB`;
  }
  return `${Math.floor((credit * 100) / 1024) / 100}GB`;
}

export default connect(
  null,
  ActionCreator
)(SpCheckSection);
