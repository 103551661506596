import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../../src/Page';
import Selectors from '../../src/Selectors';
import SpBanner from '../../src/Components/SpBanner';
import SpBuyDepositCheckSection from '../../src/Components/SpBuyDepositCheckSection';
import SpPromotionSection from '../../src/Components/SpPromotionSection';
import SpUseStepSection from '../../src/Components/SpUseStepSection';
import SpTroubleshootingSection from '../../src/Components/SpTroubleshootingSection';
import SpFaqSection from '../../src/Components/SpFaqSection';
import SpPurchaseDepositSection from '../../src/Components/SpPurchaseDepositSection';

class HomePage extends React.Component {
    render() {
        let { home } = this.props;

        return (
            <Wrapper>
                <SpBanner />
                {/* SpPurchaseDepositSection is a temporary section for testing checkout flow */}
                {/* <SpPurchaseDepositSection /> */}

                <SpBuyDepositCheckSection />
                <SpPromotionSection />
                <SpUseStepSection />
                <SpTroubleshootingSection />
                <SpFaqSection />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
   background-color: #eee;
`;


export default withPage(
  connect(
    (state, ownProps) => ({
      home: Selectors.getHomeData(state),
    }),
    null
  )(HomePage)
);
