import React from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components';
import ActionCreator from '../../src/ActionCreator';
import Selectors from '../../src/Selectors';
import * as Widget from '../../src/Components/Widget';
import * as Icon from '../../src/Components/Icon';
import * as L from '../../src/Utils/Lang'

class ProgramItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: '1',
        }
    }
    render() {
        let { item, extraCss, navActions } = this.props;
        let {quantity} = this.state;

        return (
            <Wrapper extraCss={extraCss}>
                <Title>{L.d(item, 'display_name')}</Title>
                <Widget.Container extraCss='position: relative; margin-bottom: 7px;'>
                    <Widget.FixRatioImage image={item.image} />
                    <Badge>
                        <span className='days'>{item.days}天</span>
                        <span className='flow'>/ {item.quota}GB</span>
                    </Badge>
                </Widget.Container>
                <Text extraCss='margin-bottom: 27px;'>{L.d(item, 'description')}</Text>
                {
                        <Widget.Row justify='center' extraCss='margin-bottom: 22px; flex: 1;'>
                            {
                                item.is_first_buy ? (
                                        <Input
                                            value={quantity}
                                            type='number'
                                            placeholder='輸入數量'
                                            onChange={q => {
                                                this.setState({quantity: q.target.value})
                                            }}
                                            extraCss='margin-right: 10px; background-color: transparent;'
                                        />
                                    ) : ( // top-up product cannot set quantity
                                        <Input
                                            value={quantity}
                                            type='number'
                                            disabled
                                            onChange={q => {
                                                this.setState({quantity: 1})
                                            }}
                                            extraCss='margin-right: 10px; background-color: transparent; color: grey;'
                                        />
                                    )
                            }

                            <Icon.ArrowForward
                                color='#f3f3f3'
                                style={{width: 36, height: 36, cursor: 'pointer'}}
                                onClick={() => this._addItemToCartAndGoCheckout()}
                            />
                        </Widget.Row>
                }
                <Widget.Row justify='flex-end'>
                    {
                        item.original_price ? <Price original extraCss="margin-right:10px;">$ {item.original_price}</Price> : null
                    }

                    <Price>$ {item.price}</Price>
                </Widget.Row>
            </Wrapper>
        )
    }

    _addItemToCartAndGoCheckout = () => {
        let {appActions, navActions, cartData, item} = this.props;
        let {quantity} = this.state;
        let cartAction = cartData.items[item.id] ? 'set' : 'add';
        return appActions.cart
            .configItem(cartAction, {
                id: item.id,
                data: {quantity},
            })
            .then(() => {
                navActions.push('/checkout')
            })
            .catch(error => {
            });
    }
}

const Wrapper = styled.div`
    max-width: 480px;
    padding: 22px 45px;
    background-color: #3d3d3d;

    :hover {
        background-color: #009eda;
    }

    @media screen and (max-width: 768px) {
        max-width: 355px;
    }

    @media screen and (max-width: 600px) {
        max-width: 100%;
        padding: 22px 22px;
    }

    ${props => props.extraCss};
`;

const Title = styled.div`
    margin-bottom: 16px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dd46a6;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    color: #dd46a6;
    width: 100%;
`;

const Photo = styled.img`
    width: 100%;
    position: relative;
`;

const Badge = styled.div`
    background-image: linear-gradient(90deg, #dd46a6, rgba(221, 70, 166, 0) 60%);
    position: absolute;
    bottom: 5px;
    left: 0px;
    padding: 10px 0px 0px 10px;
    width: 100%;

    & > .days {
        font-size: 40px;
        color: #f3f3f3;
    }

    & > .flow {
        font-size: 20px;
        color: #f3f3f3;
    }
   
`;

const Text = styled.div`
    text-align: right;
    color: #f3f3f3;
    ${props => props.extraCss};
`;

const Input = styled.input`
    border: 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #f3f3f3;
    padding: 8px 12px;
    background-color: #3d3d3d;
    font-size: 14px;
    color: #f3f3f3;
    width: 200px;

    & :focus {
        outline: none;
    }

    ${props => props.extraCss}
`;

const Price = styled.div`
    text-decoration: ${props => props.original && 'line-through'};
    font-size: 24px;
    color: #dd46a6;
    ${props => props.extraCss};
`;

export default  connect((state, ownProps) => ({
    cartData: Selectors.cart.getData(state),
}), ActionCreator)(ProgramItem);