import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../../src/Selectors';
import ActionCreator from '../../src/ActionCreator';
import * as Widget from './Widget';
import * as L from '../Utils/Lang';
import DepositDialog from './SpDepositDialog';

class SpPlanItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      isShowInput: false,
      isShowDepositDialog: false,
      iccidOrPins: [],
    }
  }

  render() {
    let { width, item, css } = this.props;
    let { isShowDepositDialog } = this.state;
    return (
      <Wrapper width={width} css={css}>
        <DaysBlock>
          <div className="text">{item.days}天</div>
        </DaysBlock>

        <PriceBlock css="margin-top: 60px; margin-bottom: 33px;">
          {item.original_price && <div className="original">${item.original_price}</div>}
          <div className="price">${item.price}</div>
        </PriceBlock>

        <DescriptionBlock css="margin-bottom: 20px;">
          <div className="label">A區流量</div>
          <div className="field">{this._removeUselessZero(item.quota_a)}GB</div>
        </DescriptionBlock>

        <DescriptionBlock css="margin-bottom: 30px;">
          <div className="label">B區流量</div>
          <div className="field">{this._removeUselessZero(item.quota_b)}GB</div>
        </DescriptionBlock>

        {this._renderActionButton()}

        {isShowDepositDialog && (
          <DepositDialog
            toClose={() => this.setState({ isShowDepositDialog: false })}
            onClickDeposit={iccidOrPins => {
              this.setState(
                { iccidOrPins },
                () => this._addItemToCartAndGoCheckout()
              )
            }}
          />
        )}
      </Wrapper>
    )
  }

  _removeUselessZero = num => {
    let result = parseFloat(num) 
    return result
  }

  _renderActionButton = () => {
    const { item } = this.props;
    const { quantity } = this.state;

    if (item.is_first_buy) {
      return (
        <FormBlock css="margin-bottom: 15px;">
          <Widget.QuantityInput
            placeholder="數量"
            extraCss={`
                height: 40px;
                min-width: 160px;
                padding: 8px 12px;
                -webkit-appearance: none;
                -webkit-border-radius: 0px;
                :focus {
                  outline: none;
                }
              `}
            value={quantity}
            onChange={q => {
              this.setState({ quantity: q.target.value })
            }}
          />
          <button onClick={() => this._addItemToCartAndGoCheckout()}>首次購買</button>
        </FormBlock>
      );
    } else {
      return (
        <DepositButton
          css="margin-bottom: 10px;"
          // onClick={() => this.setState({ isShowInput: true })}
          onClick={() => this.setState({ isShowDepositDialog: true })}
        >
          加購
          </DepositButton>
      );
    }
  }

  _addItemToCartAndGoCheckout = async () => {
    let { appActions, navActions, item } = this.props;
    let { quantity, iccidOrPins, isShowDepositDialog } = this.state;

    if (isShowDepositDialog) {
      try {
        for (let iccid of iccidOrPins) {
          await appActions.cart
            .configItem('add', {
              id: item.id,
              data: { quantity, iccid },
            })
        }

        navActions.push('/checkout');
      } catch (ex) {
        console.warn(ex);
      }
    }
    else {
      appActions.cart
        .configItem('add', {
          id: item.id,
          data: { quantity, is_first_buy: item.is_first_buy },
        })
        .then(() => navActions.push('/checkout'))
        .catch(err => console.warn(err));
    }
  }
}

const Wrapper = styled.div`
    padding: 10px;
    max-width: 320px;
    min-width: 230px;
    width: ${props => props.width};
    background-color: #3d3d3d;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 784px) {
        max-width: 335px;
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        max-width: 320px;
        padding: 22px 22px;
    }

    ${props => props.css};

    & > .idInput::-webkit-inner-spin-button,
    & > .idInput::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

const DaysBlock = styled.div`
    position: absolute;
    top: -34px;
    background-color: #1ea55c;
    width: 80%;
    height: 68px;

    & > .text {
        line-height: 68px;
        text-align: center;
        font-size: 36px;
        color: #dbdbdb;
    }
`;

const PriceBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    & > .original {
        text-decoration: line-through;
        font-size: 18px;
        color: gray;
    }

    & > .price {
        font-size: 27px;
        color: #dbdbdb;
    }

    ${props => props.css};
`;

const DescriptionBlock = styled.div`
    display: flex;
    align-items: center;
    border-radius: 25px;
    padding: 5px;
    background-color: #6b6b6b;
    width: 100%;

    & > .label {
        border-right: 1px solid #dbdbdb;
        width: 90px;
        text-align: center;
        font-weight: 200;
        font-size: 16px;
        color: #dbdbdb;
    }

    & > .field {
        flex: 1;
        text-align: center;
        font-weight: 200;
        font-size: 16px;
        color: #dbdbdb;
    }

    ${props => props.css};
`;

const FormBlock = styled.div`
    display: flex;
    align-items: center;

    & > button {
        min-width: 100px;
        border: 0px;
        padding: 8px 12px;
        background-color: #009eda;
        font-weight: 200;
        font-size: 16px;
        color: #dbdbdb;
        cursor: pointer;

        :focus {
            outline: none;
        }

        @media screen and (max-width: 320px) {
            padding: 8px;
        }
    }

    ${props => props.css};
`;

const DepositButton = styled.button`
    border: 0px;
    padding: 8px 12px;
    width: 85%;
    background-color: #009eda;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #dbdbdb;

    :focus {
        outline: none;
    }

    ${props => props.css};
`;


export default connect(
  (state, ownProps) => ({
    cartData: Selectors.cart.getData(state),
  }),
  ActionCreator
)(SpPlanItem);
