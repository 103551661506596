import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../../src/Selectors';
import ActionCreator from '../../src/ActionCreator';
import * as Widget from './Widget';
import SpPlanItem from './SpPlanItem';

class SpDepositSection extends React.Component {
    render() {
        let { products } = this.props;

        return (
            <Wrapper id="deposit">
                <Widget.Row
                    wrap={'true'}
                    justify='center'
                    extraCss={`
                        max-width: 1024px;
                        margin: 0 auto;
                        padding: 0px 30px;
                        @media screen and (max-width: 600px) {
                            padding: 0px 20px;
                            justify-content: center;
                        }
                    `}
                >
                    <Widget.Col align='flex-start' extraCss='margin-bottom: 100px;'>
                        <Title css="margin-bottom: 15px;">隨時隨地 即時加購</Title>
                        <Text>
                            多種加購方案供您選擇，符合您的需求隨時隨地線上加購，
                            延續您的網路使用加購後即可開通使用，節省您的寶貴時間。
                        </Text>
                    </Widget.Col>
                    <ItemContainer>
                        {
                            products.map((item, i) => {
                                if (item.is_first_buy === false) {
                                    return (

                                        <SpPlanItem
                                            key={"sp-deposit" + i}
                                            item={item}
                                            css={`
                                                margin-bottom: 80px;
                                                margin-right: 10px;
                                                @media screen and (max-width: 768px) {
                                                    margin-right: 15px;
                                                }
                                                @media screen and (max-width: 600px) {
                                                    margin-right: 0px;
                                                }
                                            `}
                                        />

                                    )
                                }
                                return;
                            })
                        }
                    </ItemContainer>
                </Widget.Row>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div``;

const ItemContainer = styled.div`    /* hard code RWD */
    flex-wrap:wrap;
    display: flex;
    flex-direction:row;

    @media screen and (max-width: 1026px) {
        width: calc(3 * 230px + 45px);
    }

    @media screen and (max-width: 784px) {
        width: calc(2 * 335px + 20px);
    }

    @media screen and (max-width: 770px) {
        width: calc(2 * 335px + 45px);
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        width: calc(2 * 320px + 30px);

    }
`;

const Title = styled.div`
    letter-spacing: 3px;
    font-size: 48px;
    color: #dbdbdb;
    ${props => props.css};

    @media screen and (max-width: 1024px){
        font-size: 33px;
    }

    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;

const Text = styled.div`
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 16px;
    color: #dbdbdb;
    ${props => props.css};
`;

export default connect(
    (state, ownProps) => ({
        products: Selectors.getSPProductList(state),
    }),
    ActionCreator
)(SpDepositSection);
