import React from 'react';
import styled from 'styled-components';
import { animateScroll as aniScroll } from 'react-scroll';

class SpBanner extends React.Component {

   _scrollSection = id => {
        const element = document.getElementById(id);
        const targetHeight = element.offsetTop;

        this.setState({ rwdMenuOpen: false });
        aniScroll.scrollTo(targetHeight - 50, { duration: 400 } );
   }

  render() {
      return (
          <Wrapper img={`/images/sp-banner/banner01.jpg`}>
              <Container>
                  <TitleText>{'全球之星\n商務上網卡'}</TitleText>
                  <SloganText>{'多國可用，線上加購，告別複雜的方案，商務人士的最佳選擇。'}</SloganText>
                  <Button onClick={() => this._scrollSection('first_buy')}>{'立即了解'}</Button>
              </Container>
          </Wrapper>
      )
  }
}

const Wrapper = styled.div`
    background-color: #ccc;
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 100vw;
    height: 800px;

    @media screen and (max-width: 600px) {
        height: 450px;
    }
`;

const Container = styled.div`
    padding: 20px;
    background: rgba(255, 255, 255, 0.6);
    max-width: 350px;
    width: 100%;
    position: absolute;
    top: 220px;
    left: 180px;

    @media screen and (max-width: 768px) {
        top: 230px;
        left: 100px;
    }

    @media screen and (max-width: 600px) {
        max-width: 250px;
        top: 120px;
        left: 50px;
    }

    @media screen and (max-width: 414px) {
        max-width: 180px;
        padding: 15px;
    }
`;

const TitleText = styled.div`
    margin-bottom: 50px;
    white-space: pre-wrap;
    line-height: 50px;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 50px;
    ${props => props.css};

    @media screen and (max-width: 600px) {
        margin-bottom: 15px;
        line-height: 30px;
        font-size: 30px;
    }

    @media screen and (max-width: 414px) {
       font-size: 26px;
    }
`;

const SloganText = styled.div`
    margin-bottom: 50px;
    white-space: pre-wrap;
    letter-spacing: 3px;
    font-size: 18px;
    ${props => props.css};

    @media screen and (max-width: 600px) {
        margin-bottom: 15px;
        font-size: 16px;
    }

    @media screen and (max-width: 414px) {
       font-size: 14px;
    }
`;

const Button = styled.div`
    padding: 10px;
    cursor: pointer;
    background-color: #0db48c;
    width: 124px;

    text-align: center;
    font-weight: 300;
    font-size: 18px;
    color: white;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }

    @media screen and (max-width: 414px) {
       font-size: 14px;
    }

`;


export default SpBanner;
