import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../../src/Selectors';
import ActionCreator from '../../src/ActionCreator';
import * as Widget from './Widget';
import SpPlanItem from './SpPlanItem';
import { animateScroll as aniScroll } from 'react-scroll';

class SpBuySection extends React.Component {
    _scrollSectionImpl = () => {
      const element = document.getElementById('questions');
      if (!element) {
        return false;
      }

      const targetHeight = element.offsetTop;

      aniScroll.scrollTo(targetHeight - 50, { duration: 400 } );
      return true;
    }

    render() {
        let { products } = this.props;

        return (
            <Wrapper id="first_buy">
                <Widget.Row
                    wrap={'true'}
                    justify='space-between'
                    extraCss={`
                        max-width: 1024px;
                        margin: 0 auto;
                        padding: 110px 30px 70px;
                        @media screen and (max-width: 900px) {
                            padding: 70px 20px;
                            justify-content: center;
                        }
                    `}
                >
                    <Widget.Col
                        extraCss={`
                            margin-bottom: 75px;
                            max-width: 480px;
                            @media screen and (max-width: 900px) {
                                max-width: 100%;
                            }
                        `}
                    >
                        <Title css="margin-bottom: 15px;">線上購買 3天內取件</Title>
                        <Text css="margin-bottom: 30px;">
                            方便的購買流程，即使是首次購買也能輕鬆上手一卡支援多國，
                            無須換卡即可暢遊不同國度，30天/12GB降速吃到飽用量充足適合隨時需要網路的您。
                        </Text>
                        <Click onClick={this._scrollSectionImpl}>參考常見問題</Click>
                    </Widget.Col>

                    {
                        products.map((item, i) => {
                            if (item.is_first_buy === true) {
                                return <SpPlanItem
                                            key={"sp-first-buy" + i}
                                            width={'100%'}
                                            item={item}
                                            css="margin-bottom: 80px;"
                                       />
                            }
                            return;
                        })
                    }
                </Widget.Row>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div``;

const Title = styled.div`
    letter-spacing: 3px;
    font-size: 48px;
    color: #dbdbdb;
    ${props => props.css};

    @media screen and (max-width: 1024px){
        font-size: 33px;
    }

    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;

const Text = styled.div`
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 16px;
    color: #dbdbdb;
    ${props => props.css};
`;

const Click = styled.div`
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;
    color: #009eda;

    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export default connect(
    (state, ownProps) => ({
        products: Selectors.getSPProductList(state),
    }),
    ActionCreator
)(SpBuySection);
